//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ArrowUpSVG from '~/assets/img/arrow-up.svg?inline'

export default {
  components: { ArrowUpSVG },
  data: () => ({
    socialLinks: [
      {
        label: 'instagram',
        href: 'https://www.instagram.com/imagenesausentes',
      },
      { label: 'facebook', href: 'https://www.facebook.com/Im%C3%A1genes-Ausentes-113007490882282' },
      { label: 'email', href: 'mailto:queesunaimagenausente@gmail.com' },
    ],
  }),
  mounted() {
    this.init()
  },
  methods: {
    init() {
      const prefersReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches

      const { footer, footerWrapper, footerContentTitle } = this.$refs

      const gsap = this.$gsap

      if (!prefersReducedMotion)
        gsap.fromTo(
          footerWrapper,
          { y: -footerWrapper.offsetHeight, opacity: 0.125 },
          {
            y: 0,
            opacity: 1,
            ease: 'none',
            scrollTrigger: {
              trigger: footer,
              start: 'top bottom',
              end: `bottom bottom`,
              scrub: true,
            },
          }
        )

      if (!prefersReducedMotion)
        gsap.fromTo(
          footerContentTitle,
          { '--x-offset': '0%' },
          {
            '--x-offset': '100%',
            ease: 'none',
            scrollTrigger: {
              trigger: footer,
              start: `bottom-=${footerContentTitle.offsetHeight} bottom`,
              end: `bottom bottom`,
              scrub: true,
            },
          }
        )
    },
    getCurrentYear() {
      return new Date().getFullYear()
    },
    scrollTop() {
      const gsap = this.$gsap
      gsap.to(window, {
        scrollTo: {
          y: 0,
        },
        duration: 2,
        ease: 'power3.inOut',
      })
    },
  },
}
